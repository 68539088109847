import { Paragraph, Button, DropdownSelector, PageContainer } from '@loveholidays/design-system';
import { Translation, useTranslation } from '@loveholidays/phrasebook';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useQuery } from 'urql';

import getSites from './getSites.gql';
import { Query } from '@AuroraTypes';
import { useSearchResultsPageUrl } from '@Components/SearchForm/useSearchResultsPageUrl';
import { useAppContext } from '@Contexts/contexts';
import { useUserPreferences } from '@Contexts/UserPreferencesContext/UserPreferencesContext';
import { isQueryResolved } from '@Core/isQueryResolved';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { createSearchSelectionStore } from '@Stores/SearchSelectionStore';

/**
 * This component intentionally uses hardcoded texts, as the agent site is only available in
 * english currently, so we can avoid putting these to translation files to keep them smaller.
 */
export const AgentSiteSelector: React.FC = () => {
  const { siteOverride } = useAppContext();
  const { t } = useTranslation();
  const getSearchResultsPageUrl = useSearchResultsPageUrl();
  const useNewBookable = useFeatureFlag('UseNewBookable');
  const { selectedAgentSite, savedSearchSelection } = useUserPreferences();

  const [{ data, fetching }] = useQuery<Query>({
    query: getSites,
  });

  if (!isQueryResolved<Query>(fetching, data)) {
    return null;
  }

  const currentSite = `${siteOverride?.domain}${siteOverride?.pathPrefix}`;
  const shortRefLookup = useNewBookable ? '/shortref-lookup' : '/book-shortref-lookup.html';

  const siteConfigJson = JSON.stringify(data.SiteConfiguration.all);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const options = useMemo(
    () =>
      data.SiteConfiguration.all
        .filter((site) => site.isVisibleInAgentSiteSelector)
        .map((site) => ({
          label: site.description,
          value: `${site.domain}${site.pathPrefix}`,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [siteConfigJson],
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const changeSite = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    selectedAgentSite.set(event.target.value);
    savedSearchSelection.set('');
    window.location.href = '/';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(
    () => (
      <section
        data-id="agent-site-selector"
        sx={{
          backgroundColor: 'backgroundSuccessmedium',
          color: 'textDefault',
          paddingY: '2xs',
          position: 'relative',
          zIndex: 'header',
          isolation: 'isolate',
        }}
      >
        <PageContainer
          sx={{
            display: 'flex',
            flexWrap: ['wrap', null, 'nowrap'],
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 'xs',
          }}
        >
          <Paragraph
            variant="small"
            sx={{ flexBasis: ['100%', null, 'auto'] }}
          >
            <Translation
              translationKey="agentSiteSelector.youAreViewing"
              components={[<strong key={1}>{currentSite}</strong>]}
            />
            <br />
            {t('agentSiteSelector.youCanChangeSite')}
          </Paragraph>
          <DropdownSelector
            variant="Light"
            size="36"
            value={currentSite!}
            options={options}
            onChange={changeSite}
          />
          <Button
            variant="Primary"
            size="36"
            href={shortRefLookup}
          >
            {t('agentSiteSelector.shortRefLookup')}
          </Button>
          <Button
            variant="Primary"
            size="36"
            href={getSearchResultsPageUrl({
              type: 'DPSRP',
              // Resets to initial store values
              selection: createSearchSelectionStore().getState(),
            })}
            trackingAction="agent-reset-search"
          >
            {t('agentSiteSelector.resetSearch')}
          </Button>
        </PageContainer>
      </section>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, currentSite],
  );
};
